<template>
  <div class="lxp_home min_height clear">
    <div class="lxp_home_box_one clear">
      <v-carousel
        cycle
        height="auto"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item v-for="(item, i) in banners" :key="`banners + ${i}`">
          <v-img
            :src="item.mpic"
            :lazy-src="item.mpic"
            min-height="960"
          >
            <template v-slot:placeholder>
              <div class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </div>
            </template>
          </v-img>
          <div class="lxp_home_box_one_ab">
            <div class="lxp_home_box_one_ab_title">
              <span>{{ item.title }}</span>
            </div>
            <div class="lxp_home_box_one_ab_des">
              <span>{{ item.subject }}</span>
            </div>
            <!-- <div class="lxp_home_box_one_ab_btn" @click="toDetails(item.id)">
              <span>了解详情</span>
            </div> -->
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div class="lxp_home_box_two clear">
      <div class="lxp_home_box_two_margin">
        <!--产品中心-->
        <div class="lxp_home_box_two_margin_one">
          <div class="lxp_home_box_two_margin_one_1">
            <h3>{{ productCenter.title }}</h3>
          </div>
          <div class="clear">
            <div class="lxp_home_box_two_margin_one_2">
              <div
                class="lxp_home_box_two_margin_one_2_1"
                v-for="(i, j) of productCenterDetails"
                :key="`pros + ${j}`"
                @click="changeImg(j)"
              >
                <div class="lxp_home_box_two_margin_one_2_sty_1">
                  <span>{{ i.name }}</span>
                </div>
                <div class="lxp_home_box_two_margin_one_2_sty_2">
                  <span>{{ i.adwords }}</span>
                </div>
                <div class="lxp_home_box_splider_num"></div>
              </div>
            </div>

            <div
              class="lxp_home_box_two_margin_one_3"
              v-for="(item, index) of productCenterDetails"
              :key="`productCenterDetails + ${index}`"
              v-show="isIndex == index"
              :style="`background:url(${item.spic}) no-repeat center`"
            >
              <template>
                <div class="lxp_home_box_two_margin_one_3_content">
                  <div class="lxp_home_box_two_margin_one_3_content_1">
                    <h4 class="lxp_home_box_two_margin_one_3_content_1">
                      {{ item.adwords }}
                    </h4>
                  </div>
                  <div
                    class="lxp_home_box_two_margin_one_3_content_2"
                    @click="toProDetails(item.id)"
                  >
                    <span>了解更多 >></span>
                  </div>
                  <div>
                    <v-img
                      :src="item.mpic"
                      contain
                      max-width="482px"
                      max-height="340px"
                    ></v-img>
                  </div>
                  <div class="lxp_home_box_two_margin_one_3_content_3">
                    <div class="lxp_home_box_two_margin_one_3_content_3_1">
                      <span v-html="item.adwords"></span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <!--帮助-->
        <div class="lxp_home_box_two_margin_two">
          <div class="lxp_home_box_two_margin_two_1">
            <span class="lxp_home_box_two_margin_two_1_span">{{
              home_help.title
            }}</span>
          </div>
          <div class="lxp_home_box_two_margin_two_2">
            <span class="lxp_home_box_two_margin_two_2_span">{{
              home_help.subject
            }}</span>
          </div>
          <div class="lxp_home_box_two_margin_two_3">
            <div
              class="lxp_home_box_two_margin_two_3_btn"
              v-for="(item, i) of support"
              :key="`helps + ${i}`"
              @click="changeBackground(i, item.link)"
            >
              <div class="lxp_home_box_two_margin_two_3_img">
                <v-img :src="item.mpic"></v-img>
              </div>
              <div class="lxp_home_box_two_margin_two_3_word">
                <span class="lxp_home_box_two_margin_two_3_word_span">{{
                  item.title
                }}</span>
              </div>
            </div>
          </div>
          <div class="lxp_home_box_two_margin_two_4" @click="toContactUs">
            <span class="lxp_home_box_two_margin_two_4_span">联系我们 >></span>
          </div>
        </div>
      </div>
    </div>

    <!--新闻中心-->
    <div class="lxp_home_box_three">
      <div class="lxp_home_box_three_margin">
        <div class="lxp_home_box_three_margin_news">
          <span>{{ blockNewsTitle }}</span>
        </div>
        <div class="lxp_home_box_three_margin_flex">
          <div class="lxp_home_box_three_margin_flex_img">
            <v-img
              :src="aNewImage"
              max-width="414"
              max-height="497"
              contain
            ></v-img>
          </div>
          <div class="lxp_home_box_three_margin_flex_nc">
            <div
              class="lxp_home_box_three_margin_flex_nc_1"
              v-for="(item, i) of news"
              :key="`news + ${i}`"
              @click="changeNewsStyle(item.id)"
            >
              <div class="lxp_home_box_three_margin_flex_nc_1_1">
                <div class="lxp_home_box_three_margin_flex_nc_1_1_m">
                  {{ item.mon }}
                </div>
                <div class="lxp_home_box_three_margin_flex_nc_1_1_y">
                  {{ item.YY }}
                </div>
              </div>
              <div class="lxp_home_box_three_margin_flex_nc_1_2">
                <div class="lxp_home_box_three_margin_flex_nc_1_1_t">
                  {{ item.title }}
                </div>
                <div class="lxp_home_box_three_margin_flex_nc_1_1_c">
                  {{ item.subject }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--商务中心-->
    <div class="lxp_home_box_four" :style="{backgroundImage:`url(${businessBlock.mpic})`}">
      <div class="lxp_home_box_four_margin">
        <div class="lxp_home_box_four_margin_one">
          <span>{{businessBlock.title}}</span>
        </div>
        <div class="lxp_home_box_four_margin_two">
          <span>{{businessBlock.subject}}</span>
        </div>
        <div class="lxp_home_box_four_margin_three">
          <!-- <div class="lxp_home_box_four_margin_three_input1"> -->
          <input type="text" placeholder="如何称呼您" v-model="names" />
          <!-- </div> -->
          <!-- <div class="lxp_home_box_four_margin_three_input2"> -->
          <input type="text" placeholder="您的联系方式" v-model="numbers" />
          <!-- </div> -->
          <v-btn class="lxp_home_box_four_margin_three_btn" @click="getMessage"
            >提交</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getHome, getBusiness } from "@/api/api";
import { mapState } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      blockNewsTitle: "",
      imgArr: [],
      monthDate: [],
      yearData: [],
      support: {},
      home_help: {},
      banners: [],
      pros: [],
      names: "",
      numbers: "",
      news: [],
      helps: [],
      items: [],
      isIndex: 0,
      isSubmit: false,
      rNum: 0,
      productCenter: {},
      productCenterDetails: [],
      aNewImage: "",
      businessBlock:{},
    };
  },
  created() {
    this.getSwipe();
    this.$nextTick(() => {
      this.initGetHome();
    });

    setTimeout(() => {
      let _res = document.getElementsByClassName("lxp_home_box_splider_num");
      _res[0].style.background = "#2eb486";
      // _res[0].style.width = "183px";
      // _res[0].style.height = "3px";
    }, 2000);
  },

  mounted() {},
  methods: {
    //产品中心跳转到对应的商品详情页
    toProDetails(_id) {
      window.scrollTo(0, 0);
      this.$router.push("/product/" + _id);
    },
    //跳转到联系我们页面
    toContactUs() {
      this.$router.push("/contact");
    },
    //帮助中心跳转页面
    toHelp(_link) {
      this.$router.push(_link);
    },
    //商务中心
    getBUsinessPage() {
      return;
    },
    //轮播图去详情页
    toDetails(_id) {
      this.$router.push("/product/" + _id);
    },

    //首页轮播图
    getSwipe() {
      return getHome({ lang: "cn" }).then((res) => {
        if (res && res.code == 0 && res.data) {
          this.banners = res.data.block.HOME_BANNER.blocklists;
        }
      });
    },
    //首页接口调用
    initGetHome() {
      return getHome({ lang: "cn" }).then((res) => {
        // console.log(res);
        if (res && res.code == 0 && res.data) {
          console.log("======",res.data);
          this.businessBlock = res.data.block.HOME_BUSINESS;
          this.home_help = res.data.block.HOME_HELP;
          this.aNewImage = res.data.block.HOME_NEWS.mpic;
          this.support = res.data.block.HOME_HELP.blocklists; //帮助模块
          this.blockNewsTitle = res.data.block.HOME_NEWS.title;
          console.log(this.support);
          this.news = res.data.news.data;
          this.productCenter = res.data.block.HOME_PRODUCT;
          this.productCenterDetails = res.data.products;
          let _arr = [];
          let _arr2 = [];
          this.news.map((item, index) => {
            _arr.push(item.created_at);
            _arr2.push(item.mpic);
          });
          this.imgArr = _arr2; //获取新闻图片数组
          //日期格式重写
          let _a = [];
          for (let i = 0; i < _arr.length; i++) {
            let a = _arr[i].indexOf("2");
            let b = _arr[i].indexOf(" ");
            _a.push(_arr[i].substring(a, b));
          }
          let _b = [];
          let _c = [];
          for (let i = 0; i < _a.length; i++) {
            let c = _a[i].slice(0, 4);
            let d = _a[i].slice(5);
            let _res = d.split("-");
            let _h = _res.join("/");

            _b.push(c);
            _c.push(_h);
          }
          this.monthDate = _c;
          this.yearData = _b;
          this.news.map((_item, _index) => {
            _item.mon = _c[_index];
            _item.YY = _b[_index];
          });
        }
      });
    },
    //点击导航改变图片
    changeImg(j) {
      console.log("============打印j", j);
      this.isIndex = j;
      let _res = document.getElementsByClassName("lxp_home_box_splider_num");
      console.log("============打印res", _res);
      if (j !== 0) {
        console.log("============打印if");
        _res[0].style.background = "transparent";
      } else {
        console.log("============打印else");
        _res[0].style.background = "#2eb486";
      }
      for (let i = 0; i < _res.length; i++) {
        if (j == i) {
          console.log("============打印被选中");
          _res[i].className += " lxp_home_box_splider_green";
        } else {
          console.log("============打印未选中");
          _res[i].classList.remove("lxp_home_box_splider_green");
        }
      }
    },
    //提交信息
    getMessage() {
      if (!this.isSubmit) {
        this.isSubmit = true;
        let _reg1 = /[\u4e00-\u9fa5_a-zA-Z]{2,20}/;
        let _reg2 = /^1[3-9]\d{9}$/;
        let _result1 = _reg1.test(this.names);
        let _result2 = _reg2.test(this.numbers);
        if (!_result1) {
          alert("请输入正确的昵称");
        } else if (!_result2) {
          alert("请输入正确的手机号码");
        } else if (!_result1 && !_result2) {
          alert("信息填写有误");
        } else if (_result1 && _result2) {
          getBusiness({
            name: this.names,
            phone: this.numbers,
            remark: "business",
          }).then((res) => {
            console.log(res);
            if (res && res.code == 0 && res.data) {
              console.log(res.data);
              alert("提交成功");
            }
          });
        }
      } else {
        alert("亲，点击太过频繁，请3分钟后再试");
      }
      setTimeout(() => {
        this.isSubmit = false;
      }, 180000);
    },
    //修改新闻背景样式
    changeNewsStyle(_id) {
      window.scrollTo(0, 0);
      this.$router.push("/newsdetails/" + _id);
     
    },

    //帮助模块
    changeBackground(i, _link) {
      this.$router.push(_link);
     
    },
  },

  computed: {
    ...mapState({
      img_path: (state) => state.imgPath + "home/",
    }),
  },
};
</script>
<style lang="scss" scoped>
.lxp_home {
  width: 100%;
  background: #ffffff;
  .lxp_home_box_one {
    position: relative;
    .lxp_home_box_one_ab {
      position: absolute;
      width: 40%;
      z-index: 100;
      top: 35%;
      left: 17%;
      .lxp_home_box_one_ab_title {
        span {
          font-size: 40px;
          font-family: Arial;
          font-weight: bold;
          color: #000000;
          opacity: 1;
        }
      }
      .lxp_home_box_one_ab_des {
        padding: 2% 0 4%;
        span {
          font-size: 30px;
          font-family: Arial;
          font-weight: 400;
          color: #949494;
          opacity: 1;
          padding: 2% 0 4%;
        }
      }
      .lxp_home_box_one_ab_btn {
        cursor: pointer;
        width: 170px;
        height: 43px;
        border: 1px solid #5d5d5d;
        opacity: 1;
        position: relative;
        span {
          position: absolute;
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          color: #919191;
          opacity: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .lxp_home_box_two {
    .lxp_home_box_two_margin {
      width: 65%;
      margin: auto;
      .lxp_home_box_splider_green {
        // width: 183px;
        // height: 3px;
        background: #2eb486;
        opacity: 1;
        border-radius: 30px;
        margin-top: 6px;
      }
      .lxp_home_box_splider_num {
        width: 183px;
        height: 3px;
      }

      .lxp_home_box_two_margin_one {
        margin: 8% auto;
        .lxp_home_box_two_margin_one_1 {
          h3 {
            width: 120px;
            height: 40px;
            font-size: 30px;
            font-family: Arial;
            font-weight: bold;
            line-height: 34px;
            color: #363636;
            opacity: 1;
            margin: 1% auto 2%;
            text-align: center;
          }
        }
        .lxp_home_box_two_margin_one_2 {
          display: flex;
          justify-content: space-around;
          align-items: center;
          .lxp_home_box_two_margin_one_2_1 {
            text-align: center;
            cursor: pointer;
          }
          .lxp_home_box_two_margin_one_2_2 {
            text-align: center;
          }
          .lxp_home_box_two_margin_one_2_3 {
            text-align: center;
          }
          .lxp_home_box_two_margin_one_2_sty_1 {
            font-size: 20px;
            font-family: Arial;
            font-weight: bold;
            line-height: 23px;
            color: #555555;
            opacity: 1;
            margin: auto;
          }
          .lxp_home_box_two_margin_one_2_sty_2 {
            font-size: 16px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            line-height: 27px;
            color: #a1a1a1;
            opacity: 1;
          }
        }
        @media screen and (min-width: 2300px) {
          .lxp_home_box_two_margin_one_2 {
            width: 80%;
            margin: auto;
            display: flex;
            justify-content: space-around;
            align-items: center;
          }
        }
        .lxp_home_box_two_margin_one_3 {
          width: 100%;
          height: 700px;
          background-repeat: no-repeat;
          // background: url("http://test.mythinkcar.cn/youda/home/productback.png")
          // no-repeat center;
          .lxp_home_box_two_margin_one_3_content {
            width: 482px;
            padding-top: 5%;
            margin: auto;
            text-align: center;
            .lxp_home_box_two_margin_one_3_content_1 {
              font-size: 20px;
              font-family: Noto Sans S Chinese;
              font-weight: 400;

              color: #2b2b2b;
              opacity: 1;
            }
            .lxp_home_box_two_margin_one_3_content_2 {
              font-size: 16px;
              font-family: Arial;
              font-weight: 400;
              color: #0c3ab8;
              opacity: 1;
              padding-top: 2%;
              padding-bottom: 5%;
              cursor: pointer;
            }
            .lxp_home_box_two_margin_one_3_content_3 {
              font-size: 16px;
              font-family: Arial;
              font-weight: 400;
              line-height: 18px;
              color: #2d2d2d;
              opacity: 1;
              padding-top: 10%;
              .lxp_home_box_two_margin_one_3_content_3_1 {
                width: 50%;
                text-align: left;
                margin: auto;
                span {
                  padding-top: 6px;
                  padding-bottom: 6px;
                  display: block;
                  width: 186px;
                  margin: auto;
                }
              }
            }
          }
        }
      }
      .lxp_home_box_two_margin_two {
        margin: 8% auto;
        .lxp_home_box_two_margin_two_1 {
          margin-top: 1%;
          text-align: center;
          .lxp_home_box_two_margin_two_1_span {
            font-size: 30px;
            font-family: Arial;
            font-weight: bold;
            color: #363636;
            opacity: 1;
          }
        }
        .lxp_home_box_two_margin_two_2 {
          text-align: center;
          padding-top: 1%;
          padding-bottom: 6%;
          .lxp_home_box_two_margin_two_2_span {
            font-size: 16px;
            font-family: Arial;
            font-weight: 400;
            color: #919191;
            opacity: 1;
          }
        }
        .lxp_home_background_boxshowda {
          width: 240px;
          height: 285px;
          background: rgba(214, 214, 214, 0.39);
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
          opacity: 1;
        }
        .lxp_home_box_two_margin_two_3 {
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;

          .lxp_home_box_two_margin_two_3_btn {
            width: 20%;
            height: 285px;
            cursor: pointer;
            .lxp_home_box_two_margin_two_3_img {
              width: 99px;
              padding-top: 20%;
              margin: auto;
              text-align: center;
            }
            .lxp_home_box_two_margin_two_3_word {
              width: 70%;
              line-height: 120px;
              margin: auto;
              text-align: center;
              .lxp_home_box_two_margin_two_3_word_span {
                font-size: 16px;
                font-family: Arial;
                font-weight: bold;
                color: #363636;
                opacity: 1;
              }
            }
          }
          .lxp_home_box_two_margin_two_3_btn:hover {
            width: 20%;
            height: 285px;
            // background: rgba(214, 214, 214, 0.39);
            // box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
            // opacity: 1;
            background: rgba(255, 255, 255, 0.39);
            box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.16);
            opacity: 1;
          }
        }
        // .lxp_home_box_two_margin_two_3 > :first-child {
        //   @extend .lxp_home_background_boxshowda;
        // }
        .lxp_home_box_two_margin_two_4 {
          width: 50%;
          margin: auto;
          text-align: center;
          padding-top: 10%;
          cursor: pointer;
          .lxp_home_box_two_margin_two_4_span {
            font-size: 16px;
            font-family: Arial;
            font-weight: 400;
            color: #0c3ab8;
            opacity: 1;
          }
        }
      }
    }
  }
  .lxp_home_box_three_card_sty {
    // width: 712px;
    // height: 140px;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.16);
    opacity: 1;
  }
  .lxp_home_box_three {
    width: 100%;
    background: #f5f5f5;
    .lxp_home_box_three_margin {
      width: 65%;
      margin: auto;
      padding: 5% 0;
      .lxp_home_box_three_margin_news {
        text-align: center;
        padding-bottom: 3%;
        span {
          font-size: 30px;
          font-family: Arial;
          font-weight: bold;
          color: #363636;
          opacity: 1;
        }
      }
      .lxp_home_box_three_margin_flex {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .lxp_home_box_three_margin_flex_img {
          width: 34%;
          // height: 497px;
        }
        .lxp_home_box_three_margin_flex_nc {
          width: 53%;
          padding-top: 12px;

          .lxp_home_box_three_margin_flex_nc_1 {
            display: flex;
            justify-content: flex-start;
            cursor: pointer;
            // border: 1px solid;
            margin-bottom: 24px;
            padding: 12px;
            .lxp_home_box_three_margin_flex_nc_1_1 {
              padding-right: 24px;
              .lxp_home_box_three_margin_flex_nc_1_1_m {
                font-size: 40px;
                font-family: Helvetica Neue;
                font-weight: 400;
                color: #9a9a9a;
                opacity: 1;
              }
              .lxp_home_box_three_margin_flex_nc_1_1_y {
                font-size: 20px;
                font-family: Helvetica Neue;
                font-weight: 400;
                color: #9a9a9a;
                opacity: 1;
              }
            }
            .lxp_home_box_three_margin_flex_nc_1_2 {
              width: 80%;
              padding: 12px;
              .lxp_home_box_three_margin_flex_nc_1_1_t {
                font-size: 20px;
                font-family: Arial;
                font-weight: bold;
                color: #2e2e2e;
                opacity: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .lxp_home_box_three_margin_flex_nc_1_1_c {
                font-size: 16px;
                font-family: Arial;
                font-weight: 400;
                color: #919191;
                opacity: 1;
                padding-top: 12px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                height: 70px;
                line-height: 32px;
              }
            }
          }
          .lxp_home_box_three_margin_flex_nc_1:hover {
            background: rgba(255, 255, 255, 0.39);
            box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.16);
            opacity: 1;
          }
        }
        //media
        @media (min-width: 890px) and (max-width: 959px) {
          .lxp_home_box_three_margin_flex_nc {
            width: 86%;
            margin-left: 5%;
            .lxp_home_box_three_margin_flex_nc_1 {
              .lxp_home_box_three_margin_flex_nc_1_2 {
                width: 77%;
                padding: 12px;
              }
            }
          }
        }

        @media (min-width: 960px) and (max-width: 1263px) {
          .lxp_home_box_three_margin_flex_nc {
            width: 80%;
            margin-left: 5%;
            .lxp_home_box_three_margin_flex_nc_1 {
              .lxp_home_box_three_margin_flex_nc_1_2 {
                width: 77%;
                padding: 12px;
              }
            }
          }
        }

        @media (min-width: 1264px) and (max-width: 1902px) {
          .lxp_home_box_three_margin_flex_nc {
            width: 70%;
            margin-left: 5%;
          }
        }
      }
    }
  }
  .lxp_home_box_four {
    width: 100%;
    // height: 543px;
    background-repeat: no-repeat;
    background-size: cover;
    .lxp_home_box_four_margin {
      width: 65%;
      margin: auto;
      padding: 5% 0;
      .lxp_home_box_four_margin_one {
        margin: auto;
        text-align: center;
        span {
          font-size: 30px;
          font-family: Noto Sans S Chinese;
          font-weight: bold;
          color: #ffffff;
          opacity: 1;
        }
      }
      .lxp_home_box_four_margin_two {
        padding-top: 1%;
        padding-bottom: 5%;
        margin: auto;
        text-align: center;
        span {
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          color: #ffffff;
          opacity: 1;
        }
      }
      .lxp_home_box_four_margin_three {
        width: 95%;
        margin: auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        input {
          width: 34%;
          height: 43px;
          border: 1px solid #ffffff;
          opacity: 1;
          color: #919191;
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          padding-left: 12px;
          padding-right: 12px;
          outline: none;
        }
        .lxp_home_box_four_margin_three_btn {
          cursor: pointer;
          width: 16%;
          height: 43px;
          background: #2eb486;
          opacity: 1;
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          line-height: 18px;
          color: #ffffff;
        }
        .lxp_home_box_four_margin_three_input1 {
          width: 40%;
          height: 43px;
          border: 1px solid #ffffff;
          opacity: 1;
        }
        .lxp_home_box_four_margin_three_input2 {
          width: 40%;
          height: 43px;
          border: 1px solid #ffffff;
          opacity: 1;
        }
      }
    }
  }
}
</style>
