<template>
  <v-app>
    <my-header></my-header>
    <!-- <waike></waike> -->
      <router-view/>
   
    <my-footer></my-footer>
  </v-app>
</template>

<script>
import Waike from './components/Waike.vue';

export default {
  components: { Waike },
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
