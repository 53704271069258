<template>
  <div class="lxp_myheader clear">
    <div
      style="width: 100%; background: #ffffff; z-index: 999; position: fixed"
    >
      <v-container style="padding: 0">
        <v-row class="lxp_myheader_row1">
          <v-col xl="2" lg="1" md="2" sm="1" class="lxp_myheader_row1_col_1">
            <div class="lxp_myheader_row1_col_1_img" @click="toHome(logo.link)">
              <v-img :src="logo.mpic" width="100%" contain></v-img>
            </div>
          </v-col>
          <v-col
            class="lxp_myheader_row1_col_2"
            width="300px"
            xl="7"
            lg="7"
            md="5"
            sm="4"
          >
            <div class="lxp_myheader_daohang">
              <div
                class="lxp_myheader_hover"
                v-for="(item, index) of menu"
                :key="`menu + ${index}`"
                @mouseover="over(index)"
                @mouseleave="leave(index)"
                @click="toPath($event, item.link, item.name, index)"
              >
                {{ item.name }}
                <table></table>
                <div class="lxp_myheader_border_hover"></div>
              </div>
            </div>

            <!-- </div> -->
          </v-col>
          <v-col
            class="lxp_p_mar_right lxp_myheader_row1_col_3"
            xl="2"
            lg="3"
            md="3"
            sm="2"
          >
            <div class="lxp_myheader_row1_col_3_div">
              <v-text-field
                single-line
                solo
                dense
                flat
                loader-height="1"
                v-model="searchValue"
                @keyup.enter="toSearch(searchValue)"
              ></v-text-field>
              <!-- <div>&nbsp;</div> -->

              <!--搜索-->
              <div
                class="lxp_myheader_row1_col_3_search"
                @click="toSearch(searchValue)"
              >
                <v-img
                  src="../../public/static/search.png"
                  width="19"
                  height="18"
                  class="lxp_col3_img"
                ></v-img>
              </div>
            </div>
          </v-col>
          <v-col
            class="lxp_myheader_row1_col_4"
            xl="1"
            lg="1"
            md="1"
            sm="1"
          >
            <div class="lxp_myheader_row1_col_4_language">
              <v-select
                :items="langdata"
                label="中文"
                solo
                flat
                @change="toEnglish($event)"
              >
              </v-select>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!--悬停显示样式-->
    <div class="lxp_waike_display_search">
      <div
        class="lxp_home_canvas"
        @mouseover="hoverProduct(indexNumber)"
        @mouseleave="leaveProduct(indexNumber)"
      >
        <div class="lxp_home_product_cariy_displaynone">
          <div class="lxp_home_product_cariy">
            <template v-if="menu[1]">
              <div
                class="lxp_home_product_cariy_vfor"
                v-for="(item, index) of menu[1].product"
                :key="`menuProduct + ${index}`"
                @click="toProduct(item.id)"
              >
                <div class="lxp_home_product_cariy_1">
                  <v-img :src="item.mpic"></v-img>
                </div>
                <div class="lxp_home_product_cariy_2">
                  <span>{{ item.name }}</span>
                </div>
                <div class="lxp_home_product_cariy_3">
                  <span>{{ item.adwords }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getTopAll, getSearch } from "@/api/api";
export default {
  data() {
    return {
      
      toPro: false,
      indexNumber: 0,
      resultHover: false,
      onHover: "isHover:hover",
      model: "",
      tab: null,
      items: ["英文"],
      logo: {},
      lang: {},
      langdata: ["中文", "English"],
      menu: [],
      searchValue: "",
      _searchList: [],
      searchList2: [],
      totalList:[],
      productList: [],
      resBoeal: false,
      stateTime: false,
      llist:[
        {mpic:"dfwieurie"},
        {mpic:"dfwieurie"},
        {mpic:"dfwieurie"},
        {mpic:"dfwieurie"},
        {mpic:"dfwieurie"},
        {mpic:"dfwieurie"},
        {mpic:"dfwieurie"},
      ]
    };
  },
  created() {
    this.getTopAllpage();
    // this.getSearchpage();
  },
  beforeMount() {},
  mounted() {},
  updated() {},
  methods: {
    //跳转到英文页面
    toEnglish(e) {
      // console.log(e);
      setTimeout(() => {
        location.reload();
      }, 1000);
      if (e == "English") {
        window.open("https://www.inewchip.com/");
      }
      // window.open("https://www.inewchip.com/")
    },
    //获取页面顶部数据
    getTopAllpage() {
      return getTopAll().then((res) => {
        if (res && res.code == 0 && res.data) {
          this.logo = res.data.logo;
          this.lang = res.data.lang;
          let langdata = res.data.langdata;
          let _arr = [];
          langdata.map((item) => {
            _arr.push(item.name);
          });
          // this.langdata = _arr;
          this.menu = res.data.menu;
          this.productList = res.data.menu[1].product;
          console.log(res, this.menu, this.productList);
        }
      });
    },

    //搜索结果
    toSearch(val) {
      return getSearch({ w: val, page: 1, size: 10 }).then((res) => {
        if (res && res.code == 0 && res.data) {
          console.log("===========",val, res, res.data);
          // this._searchList = res.data.data;//是数组
          // this._searchList2 = res.data.block;//是对象
          // this.totalList = [...this.searchList,...this.searchList2];
          // console.log(this._searchList,this._searchList2,res)
          sessionStorage.setItem("payload1",JSON.stringify(res.data.data));
        sessionStorage.setItem("payload2",JSON.stringify(res.data.block));
        this.$store.commit("resData1",res.data.data);
        this.$store.commit("resData2",res.data.block);
        }

        

        // let _string2 = JSON.stringify(this._searchList2);

        this.$router.push({
          path: "/searchresult",
          query: {
            w: val,
            // _arr: _string,
            // _arr2: _string2,
          },
        });
      });
    },

    //鼠标经过事件
    over(x) {
      // this.resBoeal = true;
      this.indexNumber = x;
      // console.log(x, this.indexNumber);
      if (x == 1 && this.toPro == false) {
        // console.log("===进来if");
        let _res = document.getElementsByClassName("lxp_home_canvas");
        _res[0].className += " lxp_hpcariy_displaynone";
      }
      // setTimeout(() => {
      //   this.resBoeal = false;
      // }, 3000);
    },

    //鼠标离开事件
    leave(x) {
      if (x == 1 && this.toPro == false) {
        setTimeout(() => {
          if (this.resBoeal) return;
          let _res = document.getElementsByClassName("lxp_home_canvas");
          _res[0].className = "lxp_home_canvas";
        }, 1000);
      } else {
        // if (this.resBoeal || this.stateTime) {
        //   console.log("=======this.resBoeal", this.resBoeal);
        //   return;
        // } else {
        //   console.log("===鼠标了离开");
        //   let _res = document.getElementsByClassName("lxp_home_canvas");
        //   _res[0].className = "lxp_home_canvas";
        // }
      }
    },

    //悬停产品区
    hoverProduct(x) {
      // this.stateTime = true;
      this.resBoeal = true;
      this.indexNumber = x;
      // console.log(x, this.indexNumber);
      if (x == 1 && this.toPro == false) {
        // console.log("===进来if");
        let _res = document.getElementsByClassName("lxp_home_canvas");
        _res[0].className += " lxp_hpcariy_displaynone";
      }
    },

    //离开产品区
    leaveProduct(x) {
      // this.stateTime = false;
      setTimeout(() => {
        this.resBoeal = false;
        let _res = document.getElementsByClassName("lxp_home_canvas");
        _res[0].className = "lxp_home_canvas";
      }, 1000);
    },

    //导航跳转
    toPath(e, _path, _name, _index) {
      // location.reload();
      let _res = document.getElementsByClassName("lxp_myheader_hover");
      let _slider = document.getElementsByClassName(
        "lxp_myheader_border_hover"
      );
      // if(e.target.innerText == _name){

      // }
      for (let i = 0; i < _res.length; i++) {
        if (i == _index) {
          _res[i].className += " greenColor";
        } else {
          _res[i].classList.remove("greenColor");
        }
      }

      for (let i = 0; i < _slider.length; i++) {
        if (i == _index) {
          _slider[i].className += " greenSlider";
        } else {
          _slider[i].classList.remove("greenSlider");
        }
      }

      // console.log("=========1", _index, e, _name);
      if (_name == "产品") {
        return;
      } else if (_name == "支持") {
        this.$router.push(_path + "/1") || location.reload();
      } else {
        this.$router.push(_path);
        // console.log("=========2")
      }
    },
    //关于我们
    toAbout() {
      this.$router.push("/aboutus");
    },
    //联系我们
    toContact() {
      this.$router.push("/contact");
    },
    toNews() {
      this.$router.push("/news");
    },
    toHome(_path) {
      this.$router.push(_path);
    },
    toProduct(id) {
      this.toPro = true;
      let _res = document.getElementsByClassName("lxp_home_canvas");
      _res[0].className = "lxp_home_canvas";
      this.$router.push("/product/" + id);
      setTimeout(() => {
        this.toPro = false;
      }, 1000);
    },
    toSupport() {
      this.$router.push("/support");
    },
  },
  watch: {
    menu() {
      
    },
  },
  computed: {
    ...mapState({
      img_path: (state) => state.imgPath + "home/",
    }),
  },
};
</script>
<style lang="scss" scoped>
.clear {
  content: "";
  display: block;
  clear: both;
  overflow: hidden;
}
.lxp_myheader {
  width: 100%;
  background: #ffffff;
  left: 0;
  top: 0;
  z-index: 999;
  .lxp_waike_display_search {
    width: 60%;
    position: fixed;
    top: 88px;
    left: 25%;
    // display: none;
    z-index: 100;
    margin: auto;
    text-align: center;
    .lxp_home_canvas {
      background-color: #ffffff;
      // width: 100%;
      height: 0px;
      position: absolute;
      top: 0;
      left: 15%;
      z-index: 100;
      transition: height 0.1s;
      -moz-transition: height 0.1s; /* Firefox 4 */
      -webkit-transition: height 0.1s; /* Safari and Chrome */
      -o-transition: height 0.1s; /* Opera */
      // transition-delay:1s;

      .lxp_home_product_cariy_displaynone {
        display: none;
        .lxp_home_product_cariy {
          min-height: 210px;
          width: 100%;
          margin: 2% auto;
          display: flex;
          justify-content: space-around;
          flex-flow: row wrap;
          flex-wrap: wrap;
          // flex-flow: row wrap;
          .lxp_home_product_cariy_vfor {
            cursor: pointer;
            // width: 30%;
            margin: 1%;
            padding: 5%;
           
            .lxp_home_product_cariy_1 {
              width: 175px;
              // height: 175px;
              // border: 1px solid rgba(112, 112, 112, 0.39);
              margin: 5% auto 0;
            }
            .lxp_home_product_cariy_2 {
              margin: auto;
              text-align: center;
              padding-top: 2%;
              span {
                font-size: 16px;
                font-family: Arial;
                font-weight: bold;
                color: #363636;
                opacity: 1;
              }
            }
            .lxp_home_product_cariy_3 {
              margin: auto;
              text-align: center;
              span {
                font-size: 16px;
                font-family: Arial;
                font-weight: 400;
                color: #b4b4b4;
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .lxp_hpcariy_displaynone {
      padding: 0 5%;
      height: auto;
      box-shadow: 0px 0px 5px 0px rgb(112 112 112);
      .lxp_home_product_cariy_displaynone {
        display: block;
      }
    }
    .lxp_hpcariy_displaynone_6 {
      height: 600px;
      .lxp_home_product_cariy_displaynone {
        display: block;
      }
    }
    .isHover:hover {
      height: 1200px;
      background-color: rgba(114, 172, 226, 0.4);
    }
  }
  // .lxp_myheader_v {
  //   position: relative;
  //   border: 1px solid;
  //   z-index: 999;
  // }

  .lxp_slider_color {
    // color: #2eb486;
    background-color: #2eb486 !important;
  }
  .lxp_slider_color:hover {
    // background-color: #2eb486 !important;
    color: #2eb486 !important;
  }
  //test
  .lxp_myheader_daohang {
    display: flex;
    width: 80%;
    justify-content: space-around;
    align-items: center;
  }
  .lxp_myheader_hover {
    cursor: pointer;
    width: 15%;
    text-align: center;
    padding: 4% 0 0;
    font-size: 16px;
    font-family: Arial;
    font-weight: bold;
    color: #363636;
    border-bottom: 3px solid #ffffff;
    border-radius: 3px;

    .lxp_myheader_border_hover {
      margin-top: 16px;
      width: 100%;
      height: 6px;
      background: #ffffff;
      opacity: 0.8;
      border-radius: 31px;
    }
  }

  .lxp_myheader_hover:hover {
    // color: rgba(46, 180, 134, 0.39) !important;
    // border-bottom: 3px solid rgba(46, 180, 134, 0.89) !important;
    // border-radius: 3px;
    color: #2eb486 !important;
    .lxp_myheader_border_hover {
      width: 100%;
      height: 6px;
      background: #2eb486;
      opacity: 0.8;
      border-radius: 31px;
    }
  }

  .greenColor {
    color: #2eb486 !important;
    // border-bottom: 2px solid #2eb486 !important;
    // background: #2eb486 !important;
  }
  .greenSlider {
    width: 100%;
    height: 6px;
    background: #2eb486 !important;
    opacity: 0.8;
    border-radius: 31px;
  }
  .lxp_myheader_row1 {
    width: 100%;
    height: 120px;
    // border: 1px solid red;
    align-items: center;
    justify-content: flex-end;
    justify-items: flex-end;
    .lxp_myheader_row1_col_1 {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      .lxp_myheader_row1_col_1_img {
        text-align: right;
        display: flex;
        align-items: center;
        width: 130px;
        height: 46px;
        cursor: pointer;
      }
    }
    .lxp_myheader_row1_col_2 {
      display: flex;
      justify-content: center;
      align-items: baseline;
      .lxp_myheader_row1_col_2_div1 {
      }
    }
    .lxp_myheader_row1_col_3 {
      display: flex;
      justify-content: center;
      align-items: baseline;
      padding: 0;

      .lxp_myheader_row1_col_3_div {
        border: 1px solid rgba(235, 235, 235, 0.8);
        display: flex;
        justify-content: space-between;
        width: 70%;
        height: 40px;
        .lxp_myheader_row1_col_3_search {
          width: 35px;
          height: 19px;
          margin-left: 6px;
          margin-top: 12px;
          cursor: pointer;
        }
      }
      // .lxp_col3_img {
      //   margin-top: 4%;
      // }
    }
    .lxp_myheader_row1_col_4 {
      justify-content: center;
      align-items: center;
      padding: 0;
      margin-top: 12px;
      .lxp_myheader_row1_col_4_language {
      }
    }
  }

  //media
  @media (min-width: 730px) and (max-width: 889px) {
    .lxp_myheader_row1 {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .lxp_myheader_row1_col_1{
        .lxp_myheader_row1_col_1_img {
        text-align: right;
        display: flex;
        align-items: center;
        width: 70px;
        height: 46px;
        cursor: pointer;
      }
      }
    }
    .lxp_myheader_daohang {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;
    }
    .lxp_waike_display_search {
      width: 60%;
      position: fixed;
      top: 88px;
      left: 13%;
      // display: none;
      z-index: 100;
      margin: auto;
      text-align: center;
    }
  }

  @media (min-width: 890px) and (max-width: 959px) {
    
    .lxp_myheader_row1 {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .lxp_myheader_row1_col_1{
        .lxp_myheader_row1_col_1_img {
        text-align: right;
        display: flex;
        align-items: center;
        width: 88px;
        height: 46px;
        cursor: pointer;
      }
      }
    }
    .lxp_myheader_daohang {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;
    }
    .lxp_waike_display_search {
      width: 60%;
      position: fixed;
      top: 88px;
      left: 13%;
      // display: none;
      z-index: 100;
      margin: auto;
      text-align: center;
    }
  }

  @media (min-width: 960px) and (max-width: 1263px) {
    .lxp_myheader_row1 {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .lxp_myheader_daohang {
      display: flex;
      width: 91%;
      justify-content: space-around;
      align-items: center;
    }
    .lxp_waike_display_search {
      width: 60%;
      position: fixed;
      top: 88px;
      left: 18%;
      // display: none;
      z-index: 100;
      margin: auto;
      text-align: center;
    }
  }

  @media (min-width: 1264px) and (max-width: 1600px) {
    .lxp_waike_display_search {
      width: 60%;
      position: fixed;
      top: 88px;
      left: 15%;
      // display: none;
      z-index: 100;
      margin: auto;
      text-align: center;
    }
  }
  @media (min-width: 1601px) and (max-width: 1903px) {
    .lxp_waike_display_search {
      width: 60%;
      position: fixed;
      top: 88px;
      left: 20%;
      // display: none;
      z-index: 100;
      margin: auto;
      text-align: center;
    }
  }
}
</style>