<template>
  <div id="lxp_waike_display_search" >
    <canvas
      id="lxp_home_canvas"
      width="640"
      height="360"
      @click="hiddenCanvas"
    ></canvas>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { getNotice, searchGood } from "@/api/api";
export default {
  name: "Waike",
  data() {
    return {
      inputValue: "",
      searchList: [],
    };
  },
  created() {},
  mounted() {},
  beforeUpdate() {      
  },
  updated(){

  },
  methods: {
      ...mapMutations(["changeSearchBolean"]),
    //搜索框的值
    searchResult(val) {
      this.toCloseSearch();
      if (val) {
        //发送请求
      } else {
        alert("Please enter the product name");
      }
      return searchGood({ name: val }).then((res) => {
        if (res && res.code == 0 && res.data) {
          this.searchList = res.data;
        }
        let _string = JSON.stringify(this.searchList);
        this.$router.push({
          path: "/searchresult",
          query: { _arr: _string, value: val },
        });
      });
    },

    //关闭搜索
    toCloseSearch() {
      this.changeSearchBolean(false);
    
    },

    //画布蒙版
    hiddenCanvas() {
     
    },
  },
    
  computed: {
    ...mapState({
      img_path: (state) => state.imgPath + "home/",
      serachBolean: (state) => state.serachBolean,
    }),
  },
};
</script>
<style lang="scss" scoped>
#lxp_waike_display_search {
  width: 100%;
  // height: 146px;
  background: #ffffff;
  position: absolute;
  top: -1500;
  left: 0;
  // display: none;
  z-index: 100;
  margin: auto;
  #lxp_home_canvas {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 1200px;
    position: absolute;
    top: 70;
    left: 0;
    z-index: 100;
  }
  .lxp_waike_display_search_logo {
    float: left;
  }
  .lxp_waike_display_search_clu_input {
    width: 52%;
  }
  .lxp_waike_display_search_del {
    cursor: pointer;
    .ic_child {
      font-size: 24px;
      color: #000000;
      text-align: center;
    }
  }
  .lxp_waike_display_search_margin {
    margin: auto;
    width: 73%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 64px;
  }
  input {
    width: 95%;
    height: 34px;
    font-size: 16px;
    display: block;
    margin: 10px auto;
    border-top: 1px solid rgba(211, 211, 211, 0.5);
    border-right: 1px solid rgba(211, 211, 211, 0.5);
    border-bottom: 1px solid rgba(211, 211, 211, 0.5);
    padding-left: 6px;
    float: left;
  }
  .lxp_waike_search_1 {
    cursor: pointer;
    float: left;
    padding: 6px;
    margin-top: 10px;
    border-top: 1px solid rgba(211, 211, 211, 0.5);
    border-left: 1px solid rgba(211, 211, 211, 0.5);
    border-bottom: 1px solid rgba(211, 211, 211, 0.5);
  }
}
</style>