import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    urlData1:JSON.parse(sessionStorage.getItem("payload1")),
    urlData2:JSON.parse(sessionStorage.getItem("payload2")),
    imgPath:"http://test.studymachine.mythinkcar.cn/youda/",
    totalData:sessionStorage.getItem("total"),
    pageSize:sessionStorage.getItem("page"),
  },
  mutations: {
    resData1(state,_url){
      state.urlData1 = _url;
    },
    resData2(state,_url){
      state.urlData2 = _url;
    },
    selectTotal(state,total){
      state.totalData = total;
    },
    changePage(state,page){
      state.pageSize = page;
    },
  },
  actions: {
  },
  modules: {
  }
})
