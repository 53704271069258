<template>
  <div class="lxp_myfooter clear">
    <v-bottom-navigation v-model="value" dark height="476">
      <v-container>
        <div class="lxp_footer_row" style="font-size: 20px; margin-top: 24px">
          <div class="lxp_footer_flex_1">
            <!--左边部分-->
            <div
              v-for="(item, index) of categorylists"
              :key="`categorylists + ${index}`"
              class="lxp_footer_row_title_sty"
            >
              <p @click="toPath(item.link)">{{ item.name }}</p>
              <div
                v-for="(i, j) of item.blocklists"
                :key="`blocklists + ${j}`"
                class="lxp_footer_row_span_sty"
                :class="(i.link&&`lxp_footer_cursor`)"
                @click="toPath(i.link)"
              >
                <span>{{ i.title }}</span>
              </div>
            </div>
          </div>
          <div class="lxp_footer_flex_2">
            <div class="lxp_footer_flex_2_1">
              <span>{{ footer_qr.title }}</span>
            </div>
            <div class="lxp_footer_flex_2_2">
              <span v-html="footer_qr.content"></span>
            </div>
            <!-- <div class="lxp_footer_flex_2_3">
              <v-img :src="footer_qr.mpic"></v-img>
            </div> -->
          </div>
          <!-- <div class="lxp_footer_row" style="font-size: 16px">
            <div></div>
          </div>
        </div>
        <v-row class="lxp_footer_row" style="font-size: 16px">
          <v-col>公司新闻</v-col>
          <v-col>产品说明书</v-col>
          <v-col>隐私政策</v-col>
          <v-col>电话：0755-21036827</v-col>
          <v-col></v-col>
        </v-row>

        <v-row class="lxp_footer_row_1" style="font-size: 16px">
          <v-col>加入我们</v-col>
          <v-col>诊断知识</v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col class="lxp_col4">
            <div class="lxp_footer_ewm_style">
              <v-img :src="img_path + 'ewm.png'" width="50%"></v-img>
            </div>
          </v-col>
        </v-row>-->
          
        </div>
        <div class="lxp_footer_last_row">
            <div>{{copyRight}}</div>
          </div>
      </v-container>
    </v-bottom-navigation>
  </div>
</template>


<script>
import { mapState } from "vuex";
import { getFooterAll } from "@/api/api";
export default {
  data() {
    return {
      copyRight:"",
      footer_qr: {},
      categorylists: [],
      value: "recent",
    };
  },

  created() {
    this.getFooterAllpage();
  },
  mounted() {
    this.$nextTick(()=>{
      let _res = document.getElementsByClassName("lxp_footer_row_span_sty");
      // console.log(_res);
      setTimeout(() => {
        for(let i = 0; i < _res.length; i++){
          if(_res[i].innerText == "邮箱：service@inewchip.com" || _res[i].innerText == "电话：0755-21036827"){
            

          }
      // console.log("============打印LOOK",_res[i].innerText)
    }
      },2000)
    
    })
    
  },
  updated() {},
  methods: {
    //页面尾部数据
    getFooterAllpage() {
      return getFooterAll().then((res) => {
        if (res && res.code == 0 && res.data) {
          this.categorylists = res.data.FOOTER_NAV.categorylists;
          console.log(res.data)
          this.footer_qr = res.data.FOOTER_QR;
          this.copyRight = res.data.FOOTER_VERSION.subject;

        }
      });
    },

    //跳转相应页面
    toPath(_path) {
       if(_path == "/" || _path == null){
         console.log("i am is")
        return;
      }else{
        this.$router.push(_path);
      window.scrollTo(0, 0);
      }
    },

    scrollToTop() {
      const t = document.documentElement.scrollTop || document.body.scrollTop;
    },
  },
  computed: {
    // img_Path(){
    //   return this.$store.state.imgPath;
    // }
    ...mapState({
      img_path: (state) => state.imgPath + "home/",
    }),
  },
};
</script>

<style lang="scss" scoped>
.clear {
  content: "";
  display: block;
  clear: both;
  overflow: hidden;
}

.lxp_myfooter {
  width: 100%;
  .lxp_footer_row {
    padding-top: 3%;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    .lxp_footer_flex_1 {
      width: 80%;
      .lxp_footer_row_title_sty {
        width: 20%;
        float: left;
        margin: 0 2.5% 0 0;
        p {
          cursor: pointer;
          margin: 0;
          font-size: 20px;
          font-family: Arial;
          font-weight: bold;
          color: #ffffff;
          opacity: 1;
        }
        .lxp_footer_row_span_sty {
          width: 100%;
          margin: 10% 0;
          span {
            
            font-size: 16px;
            font-family: Arial;
            font-weight: 400;
            color: #afafaf;
            opacity: 1;
          }
        }
        .lxp_footer_cursor{
          cursor: pointer;
        }
      }
    }
    .lxp_footer_flex_2 {
      width: 20%;
      .lxp_footer_flex_2_1 {
        span {
          font-size: 20px;
          font-family: Arial;
          font-weight: bold;
          color: #ffffff;
          opacity: 1;
        }
      }
      .lxp_footer_flex_2_2 {
        padding: 10% 0;
        span {
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          color: #afafaf;
          opacity: 1;
        }
      }
      .lxp_footer_flex_2_3 {
        width: 155px;
        height: 155px;
        border: 1px solid white;
      }
    }

    .lxp_footer_ewm_style {
      width: 153px;
      height: 153px;
      margin-top: -24px;
    }
  }
  .lxp_footer_row_1 {
    display: flex;
    justify-content: center;
    width: 80%;
    margin: 12px auto 0;
  }
  .lxp_footer_last_row {
    width: 100%;
    height: 50px;
    margin: 1% auto 0px;

    text-align: center;
  }
}
</style>