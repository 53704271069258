import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from './plugins/vuetify'
import MyHeader from './components/MyHeader'
import MyFooter from './components/MyFooter'
import Waike from './components/Waike'
import "./styles/index.scss";
import vuetify from './plugins/vuetify'


const opts = {}
Vue.config.productionTip = false
Vue.use(Vuetify);
// export default new Vuetify({opts})
Vue.component('my-header',MyHeader);
Vue.component('my-footer',MyFooter);
Vue.component("waike",Waike);



new Vue({
  router,
  store,
  Vuetify,
  vuetify,
  render: h => h(App)
}).$mount('#app')
