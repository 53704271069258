// import { EOrderStatus } from "@/utils/enum";
import { request } from ".";
const qs = require('qs');


/**
 *  获取页面顶部数据
 */
 export async function getTopAll(params) {
  const res = await request("/apic/v1/top/all", {
    method: "get",
    params: params,
  });
  return res;
}

/**
 *  页面尾部数据
 */
 export async function getFooterAll(params) {
  const res = await request("/apic/v1/footer/all", {
    method: "get",
    params: params,
  });
  return res;
}


/**
 *  首页数据
 */
 export async function getHome(params) {
  const res = await request("/apic/v1/home/main", {
    method: "get",
    params: params,
  });
  return res;
}


/**
 *  产品页面数据
 */
 export async function getDetail(params) {
  const res = await request("/apic/v1/product/detail", {
    method: "get",
    params: params,
  });
  return res;
}


/**
 *  支持页面数据
 */
 export async function getSupport(params) {
  const res = await request("/apic/v1/support/category", {
    method: "get",
    params: params,
  });
  return res;
}


/**
 *  支持页面列表页
 */
 export async function getSupportList(params) {
  const res = await request("/apic/v1/support/lists", {
    method: "get",
    params: params,
  });
  return res;
}

/**
 *  支持页面详情页
 */
 export async function getSupportDetail(params) {
  const res = await request("/apic/v1/support/detail", {
    method: "get",
    params: params,
  });
  return res;
}

/**
 *  新闻分类页
 */
 export async function getNewsCategory(params) {
  const res = await request("/apic/v1/news/category", {
    method: "get",
    params: params,
  });
  return res;
}


/**
 *  新闻列表页
 */
 export async function getNewsLists(params) {
  const res = await request("/apic/v1/news/lists", {
    method: "get",
    params: params,
  });
  return res;
}

/**
 *  获取新闻详情页
 */
 export async function getNewsDetails(params) {
  const res = await request("/apic/v1/news/detail", {
    method: "get",
    params: params,
  });
  return res;
}

/**
 *  获取关于我们页
 */
 export async function getAbout(params) {
  const res = await request("/apic/v1/about/main", {
    method: "get",
    params: params,
  });
  return res;
}

/**
 *  获取工作岗位接口
 */
 export async function getJobs(params) {
  const res = await request("/apic/v1/about/jobs", {
    method: "get",
    params: params,
  });
  return res;
}


/**
 *  联系我们页
 */
 export async function getContact(params) {
  const res = await request("/apic/v1/contact/main", {
    method: "get",
    params: params,
  });
  return res;
}


/**
 *  获取验证码数据接口
 */
 export async function getVerifydata(params) {
  const res = await request("/apic/v1/contact/verifydata", {
    method: "get",
    params: params,
  });
  return res;
}


/**
 *  获取联系我们数据发送接口
 */
 export async function getContactdata(params) {
  const res = await request("/apic/v1/contact/contact", {
    method: "post",
    data: params,
  });
  return res;
}


/**
 *  搜索页
 */
 export async function getSearch(params) {
  const res = await request("/apic/v1/search/main", {
    method: "get",
    params: params,
  });
  return res;
}


/**
 *  商务合作接口
 */
 export async function getBusiness(params) {
  const res = await request("/apic/v1/home/business", {
    method: "post",
    data: params,
  });
  return res;
}


/**
 *  服务数据接口
 */
 export async function getService(params) {
  const res = await request("/apic/v1/page/Service_Agreement", {
    method: "get",
    params: params,
  });
  return res;
}


/**
 *  隐私数据接口
 */
 export async function getPrivacy(params) {
  const res = await request("/apic/v1/page/Privacy_Policy", {
    method: "get",
    params: params,
  });
  return res;
}

